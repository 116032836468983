/*
   Bootstrap5のpopoverを、画面のどこ(トグル要素含む)を押しても閉じるように拡張する

   Claude.ai:
   > Bootstrap 5のドキュメントでは確かに複数のトリガーを空白区切りで指定できるように書かれています。しかし、実際の動作に関して、複数トリガーの指定が可能とされていますが、実際の動作は不安定です。
   > この問題については、Bootstrap's GitHub Issuesでも時折議論されていますが、完全な解決には至っていない状況です。

   app/views/your_view.html.slim の例
   注：data-bs-toggle='popover' を入れないこと

   div data-controller="popover"
     a[
       href="#"
       data-popover-target="link"
       data-action="click->popover#toggle"
       data-bs-content="説明文がここに入ります"
       data-bs-placement="bottom"
       class="term-link"
     ] 用語
 */

import { Controller } from '@hotwired/stimulus'
import { Popover } from 'bootstrap'

export default class extends Controller {
  static targets = ['link']

  connect() {
    // manualトリガーで初期化
    this.popover = new Popover(this.linkTarget, {
      trigger: 'manual', // 手動制御モード
      container: 'body',
    })

    // イベントリスナーの設定
    this.linkTarget.addEventListener('focusin', this.handleFocusIn.bind(this))
    this.linkTarget.addEventListener('focusout', this.handleFocusOut.bind(this))
    document.addEventListener('click', this.handleDocumentClick.bind(this))
  }
  disconnect() {
    // イベントリスナーの削除
    this.linkTarget.removeEventListener(
      'focusin',
      this.handleFocusIn.bind(this),
    )
    this.linkTarget.removeEventListener(
      'focusout',
      this.handleFocusOut.bind(this),
    )
    document.removeEventListener('click', this.handleDocumentClick.bind(this))

    if (this.popover) {
      this.popover.dispose()
    }
  }

  // フォーカスを受け取ったとき
  handleFocusIn() {
    this.popover.show()
  }

  // フォーカスが外れたとき
  handleFocusOut(event) {
    // ポップオーバーの内容にフォーカスが移動した場合は閉じない
    if (this.isPopoverContent(event.relatedTarget)) {
      return
    }
    this.popover.hide()
  }

  // クリックイベントの処理
  handleDocumentClick(event) {
    // クリックされた要素がリンクでもポップオーバーの内容でもない場合
    if (
      !this.linkTarget.contains(event.target) &&
      !this.isPopoverContent(event.target)
    ) {
      this.popover.hide()
    }
  }

  // クリック時のトグル処理
  toggle(event) {
    event.preventDefault()
    event.stopPropagation()
    this.popover.toggle()
  }

  // ポップオーバーのコンテンツ要素かどうかを判定
  isPopoverContent(element) {
    if (!element) return false
    const popoverElement = document.querySelector('.popover')
    return popoverElement?.contains(element)
  }
}

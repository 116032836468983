import {
  defaultKeymap,
  history,
  historyKeymap,
  indentWithTab,
} from '@codemirror/commands'
import { EditorState, StateEffect, StateField } from '@codemirror/state'
import { Decoration, EditorView, keymap, placeholder } from '@codemirror/view'
import { Controller } from 'stimulus'

const addLineHighlight = StateEffect.define()
const lineHighlightField = StateField.define({
  create() {
    return Decoration.none
  },
  update(lines, tr) {
    lines = lines.map(tr.changes)
    for (let e of tr.effects) {
      if (e.is(addLineHighlight)) {
        lines = Decoration.none
        if (e.value >= 0) {
          lines = lines.update({ add: [lineHighlightMark.range(e.value)] })
        }
      }
    }
    return lines
  },
  provide: f => EditorView.decorations.from(f),
})
const lineHighlightMark = Decoration.line({
  attributes: { class: 'bg-warning-subtle' },
})

export default class extends Controller {
  static targets = ['view', 'source', 'wordCountText', 'wordCount']
  static values = {
    editable: { type: Boolean, default: true },
    wordCountMax: { type: Number, default: 2500 },
  }

  connect() {
    const doc = this.sourceTarget.value
    this.editorView = new EditorView({
      state: EditorState.create({
        doc,
        extensions: [
          lineHighlightField,
          history(),
          placeholder('ここに記事内容を書いてください'),
          EditorView.lineWrapping,
          EditorView.editable.of(this.editableValue),
          EditorState.readOnly.of(!this.editableValue),
          keymap.of([...defaultKeymap, ...historyKeymap, indentWithTab]),
          EditorView.updateListener.of(update => {
            if (update.docChanged) {
              const text = update.state.doc.toString()
              this.sourceTarget.value = text
              this.updateWordCount(text.replaceAll(/\s/g, '').length)
              this.dispatch('change', { detail: { text } })
            }
          }),
        ],
      }),
      parent: this.viewTarget,
    })
    this.updateWordCount(doc.replaceAll(/\s/g, '').length)
  }

  disconnect() {
    this.editorView.destroy()
  }

  updateWordCount(count) {
    this.wordCountTarget.innerText = count
    if (count > this.wordCountMaxValue) {
      this.wordCountTextTarget.classList.add('over-limit')
    } else {
      this.wordCountTextTarget.classList.remove('over-limit')
    }
  }

  toggleHeading(level) {
    const currentPosFrom = this.editorView.state.selection.main.from
    const currentPosTo = this.editorView.state.selection.main.to
    const lineFrom = this.editorView.state.doc.lineAt(currentPosFrom)
    const lineTo = this.editorView.state.doc.lineAt(currentPosTo)
    const changes = []
    let selection
    if (currentPosFrom === currentPosTo && lineFrom.text === '') {
      changes.push({
        from: currentPosFrom,
        insert: `${'='.repeat(level)} `,
      })
      selection = {
        anchor: currentPosFrom + level + 1,
      }
    } else {
      for (let i = lineFrom.number, append; i <= lineTo.number; i++) {
        const line = this.editorView.state.doc.line(i)
        const matched = line.text.match(/^(={2,4})([ \t]+)(.*?)(?:[ \t]+\1)?$/)
        if (append === undefined) {
          append = !matched || matched[1].length !== level
        }
        if (append && line.text !== '') {
          if (matched) {
            if (matched[1].length !== level) {
              changes.push({
                from: line.from,
                to: line.from + matched[1].length,
                insert: `${'='.repeat(level)}`,
              })
            }
          } else {
            changes.push({
              from: line.from,
              insert: `${'='.repeat(level)} `,
            })
          }
        } else if (matched) {
          changes.push({
            from: line.from,
            to: line.from + matched[1].length + matched[2].length,
            insert: '',
          })
        }
      }
    }
    this.editorView.dispatch({
      changes,
      selection,
    })
    setTimeout(() => {
      this.editorView.focus()
    }, 200)
  }

  toggleHeading2() {
    this.toggleHeading(2)
  }

  toggleHeading3() {
    this.toggleHeading(3)
  }

  toggleUnorderedList() {
    const currentPosFrom = this.editorView.state.selection.main.from
    const currentPosTo = this.editorView.state.selection.main.to
    const lineFrom = this.editorView.state.doc.lineAt(currentPosFrom)
    const lineTo = this.editorView.state.doc.lineAt(currentPosTo)
    const changes = []
    let selection
    if (currentPosFrom === currentPosTo && lineFrom.text === '') {
      changes.push({
        from: currentPosFrom,
        insert: '* ',
      })
      selection = {
        anchor: currentPosFrom + 2,
      }
    } else {
      for (let i = lineFrom.number, append; i <= lineTo.number; i++) {
        const line = this.editorView.state.doc.line(i)
        const matched = line.text.match(/^([ \t]*)(\*\**)([ \t]+)(.*)$/)
        if (append === undefined) {
          append = !matched
        }
        if (append && line.text !== '') {
          if (matched) {
            changes.push({
              from: line.from + matched[1].length,
              insert: '*',
            })
          } else {
            changes.push({
              from: line.from,
              insert: '* ',
            })
          }
        } else if (matched) {
          if (matched[2].length > 1) {
            changes.push({
              from: line.from + matched[1].length,
              to: line.from + matched[1].length + 1,
              insert: '',
            })
          } else {
            changes.push({
              from: line.from,
              to:
                line.from +
                matched[1].length +
                matched[2].length +
                matched[3].length,
              insert: '',
            })
          }
        }
      }
    }
    this.editorView.dispatch({
      changes,
      selection,
    })
    setTimeout(() => {
      this.editorView.focus()
    }, 200)
  }

  toggleBold() {
    const currentPosFrom = this.editorView.state.selection.main.from
    const currentPosTo = this.editorView.state.selection.main.to
    const selectedText = this.editorView.state.doc.sliceString(
      currentPosFrom,
      currentPosTo,
    )
    if (selectedText === '') {
      this.editorView.dispatch({
        changes: {
          from: currentPosFrom,
          insert: '**',
        },
        selection: {
          anchor: currentPosFrom + 1,
        },
      })
    } else {
      const bolded = selectedText.match(/^\*(.*)\*$/)
      let insertText = `*${selectedText}*`
      if (bolded) {
        insertText = bolded[1]
      }
      this.editorView.dispatch({
        changes: {
          from: currentPosFrom,
          to: currentPosTo,
          insert: insertText,
        },
      })
    }
    setTimeout(() => {
      this.editorView.focus()
    }, 200)
  }

  insertLink() {
    const currentPosFrom = this.editorView.state.selection.main.from
    const currentPosTo = this.editorView.state.selection.main.to
    const selectedText = this.editorView.state.doc.sliceString(
      currentPosFrom,
      currentPosTo,
    )
    const insertText = `link:[${selectedText}]`
    const newPos = currentPosFrom + 5
    this.editorView.dispatch({
      changes: {
        from: currentPosFrom,
        to: currentPosTo,
        insert: insertText,
      },
      selection: {
        anchor: newPos,
      },
    })
    setTimeout(() => {
      this.editorView.focus()
    }, 200)
  }

  insertImage(slot) {
    const insertText = `image::slot-${slot}[]`
    const currentPos = this.editorView.state.selection.main.to
    const newPos = currentPos + insertText.length - 1
    this.editorView.dispatch({
      changes: {
        from: currentPos,
        insert: insertText,
      },
      selection: {
        anchor: newPos,
      },
    })
    setTimeout(() => {
      this.editorView.focus()
    }, 500)
  }

  focus(event) {
    if (event?.target?.classList.contains('view')) {
      this.editorView.focus()
    }
  }

  highlightLine({ detail: { line } }) {
    if (!line) {
      this.editorView.dispatch({ effects: addLineHighlight.of(null) })
      return
    }
    const docPosition = this.editorView.state.doc.line(line).from
    this.editorView.dispatch({ effects: addLineHighlight.of(docPosition) })
  }
}
